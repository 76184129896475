import { Button, Heading, Body } from '@moneylion/mlds-web'
import React, { useState, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { formats } from '@root/utils'
import labels from './labels'

interface EmailSentViewProps {
  handlePasswordReset: (isResend: boolean) => void
}

const TIME = 60

const EmailSentView: React.FunctionComponent<EmailSentViewProps> = ({
  handlePasswordReset,
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(TIME)
  const intervalId = React.useRef<NodeJS.Timeout | undefined>(undefined)
  const router = useRouter()
  const theme = useTheme()

  const handleResend = () => {
    callInterval()
    setRemainingTime(TIME)
    handlePasswordReset(true)
  }

  const callInterval = () => {
    clearInterval(intervalId.current)

    intervalId.current = setInterval(() => {
      setRemainingTime((val) => {
        if (val === 1) {
          clearInterval(intervalId.current)
        }
        return val - 1
      })
    }, 1000)
  }

  useEffect(() => {
    callInterval()

    return () => {
      clearInterval(intervalId.current)
    }
  }, [])

  return (
    <div className="forgot-password-card">
      <div className="heading">
        <Heading style={{ marginBottom: '16px' }} size="medium">
          {labels.emailSent.resetPasswordEmailSent}
        </Heading>
        <Body
          className="mb-5"
          size="medium"
          color={theme.color?.CONTENT_SECONDARY}
        >
          {labels.emailSent.checkYourEmail}
        </Body>
      </div>
      <Button fullWidth onClick={() => router.push('/login')}>
        {labels.emailSent.backToLogIn}
      </Button>
      <div style={{ display: 'flex' }} className="mt-3">
        <div>
          <Body
            className="mr-2"
            size="medium"
            color={theme.color?.CONTENT_SECONDARY}
          >
            {labels.emailSent.didntReceiveEmail}{' '}
          </Body>
        </div>

        <Body
          onClick={remainingTime > 0 ? undefined : handleResend}
          size="medium"
          className="underline"
          style={{ cursor: `${remainingTime > 0 ? 'not-allowed' : 'pointer'}` }}
        >
          {remainingTime === 0
            ? labels.emailSent.resendCode
            : labels.emailSent.resendIn(formats.time(remainingTime))}
        </Body>
      </div>
    </div>
  )
}

export { EmailSentView }
