const labels = {
  forgotPassword: {
    forgotYourPassword: 'Forgot your password?',
    resetYourPasswordInfo:
      'To reset your password, enter the email address you use to sign in to MoneyLion.',
    email: 'Email',
    resetYourPassword: 'Reset your password',
    backTo: 'Back to',
    logIn: 'Log in',
  },
  emailSent: {
    resetPasswordEmailSent: 'Reset password email was sent!',
    checkYourEmail:
      'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.',
    backToLogIn: 'Back to log in',
    didntReceiveEmail: 'Didn’t receive your email?',
    resendCode: 'Resend Code',
    resendIn: (time: string) => `Resend in ${time}`,
  },
}

export default labels
