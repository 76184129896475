import React from 'react'

const SentImage: React.FunctionComponent<{ className: string }> = ({
  className,
}) => (
  <svg
    width={120}
    height={120}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M45.202 6.425c8.566-8.567 22.455-8.567 31.021 0a10.968 10.968 0 0 0 7.756 3.212h.712c13.065 0 23.656 10.591 23.656 23.656 0 3.365 1.337 6.592 3.717 8.972l.503.504c9.911 9.91 9.911 25.977 0 35.887l-1.216 1.217a10.256 10.256 0 0 0-3.004 7.251c0 11.722-9.502 21.223-21.223 21.223-2.72 0-5.328 1.081-7.251 3.004l-1.217 1.216c-9.91 9.911-25.977 9.911-35.887 0l-.504-.503a12.687 12.687 0 0 0-8.972-3.717c-13.065 0-23.656-10.59-23.656-23.656v-.712c0-2.91-1.155-5.699-3.212-7.756-8.567-8.566-8.567-22.455 0-31.021a10.968 10.968 0 0 0 3.212-7.755v-1.72c0-14.41 11.68-26.09 26.09-26.09h1.72c2.908 0 5.698-1.155 7.755-3.212Z"
      fill="url(#a)"
    />
    <path
      d="M83.219 42.114a2.667 2.667 0 0 1 0 3.771L52.552 76.552a2.667 2.667 0 0 1-3.771 0l-12-12a2.667 2.667 0 0 1 3.771-3.771l10.115 10.114 28.78-28.78a2.667 2.667 0 0 1 3.772 0Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="a"
        x1={-19.032}
        y1={60}
        x2={148.855}
        y2={60}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CD87" />
        <stop offset={1} stopColor="#00C8D6" />
      </linearGradient>
    </defs>
  </svg>
)

export { SentImage }
