import React, { useEffect, useState } from 'react'
import { Button, Input, Body, Heading } from '@moneylion/mlds-web'
import { useEventListener } from 'usehooks-ts'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { createAbortController, tabCloseEventTrigger } from '@root/helpers'
import { useAuthStore, useUIStore } from '@root/store'
import { segmentApi, sessionStorageApi } from '@root/api'
import { formats, validate, validateToken, validations } from '@root/utils'
import { ENV } from '@root/config'
import { LOGIN_LABEL } from '@root/constants'
import { useRemoteConfig } from '@root/hooks'
import { REMOTE_CONFIG_FEATURE_FLAG } from '@root/constants/featureFlagNames'

const { controller, signal } = createAbortController()
tabCloseEventTrigger(controller)

const LoginCard: React.FunctionComponent = () => {
  const theme = useTheme()
  const router = useRouter()
  const login = useAuthStore((state) => state.login)
  const getAccessToken = useAuthStore((state) => state.getAccessToken)
  const removeAccessToken = useAuthStore((state) => state.removeAccessToken)
  const setShowAppDialog = useUIStore((state) => state.setShowAppDialog)
  const [email, setEmail] = useState<string>(
    (router.query?.email as string) || ''
  )
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasFailedLogin, setHasFailedLogin] = useState<boolean>(false)
  const { treatment } = useRemoteConfig(
    REMOTE_CONFIG_FEATURE_FLAG.WEB.allowUserIdOnSegment
  )
  const userIdTreatment = treatment?.asBoolean()

  const setShowNotificationModal = useUIStore(
    (state) => state.setShowNotificationModal
  )

  useEffect(() => {
    const token = getAccessToken()
    const mfaOptions = sessionStorageApi.get('mfaOptions')

    if (validateToken(token) && Boolean(mfaOptions)) {
      router.replace('/login/mfa-options')
    } else {
      removeAccessToken()
    }

    if (router.query?.newPassword) {
      setShowNotificationModal(true, {
        text: 'You’ve successfully reset your password!',
        icon: 'check',
        autoHideDuration: 5000,
      })
    }
  }, [])

  const onClickLogin = async () => {
    try {
      setIsLoading(true)
      const data = await login(
        { username: formats.email(email), password },
        signal,
        userIdTreatment
      )

      segmentApi.trackWithOSPlatform('id_login_submit', {
        flow: 'login',
        mfa_required: data.mfaRequired || false,
        status: 'success',
      })

      if (data.mfaRequired) {
        sessionStorageApi.set('mfaOptions', JSON.stringify(data.mfaOptions))
        router.replace('/login/mfa-options')
      } else {
        segmentApi.trackWithOSPlatform('id_login_success', { flow: 'login' })
        router.replace('/products-offers')
      }
    } catch (error: any) {
      segmentApi.trackWithOSPlatform('id_login_submit', {
        flow: 'login',
        status: 'fail',
        fail_reason: error?.code,
      })

      setIsLoading(false)

      if (['DA004', 'DA007'].includes(error?.code)) {
        setHasFailedLogin(true)
      } else {
        setShowAppDialog(true)
      }
    }
  }

  const shouldDisableButton = (): boolean => {
    return !validate({ email, password }, validations.login)
  }

  const onPressEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && !shouldDisableButton()) {
      onClickLogin()
    }
  }

  useEventListener('keypress', onPressEnter)

  return (
    <>
      <div className="login-card">
        <Heading
          size="large"
          style={{
            marginBottom: '40px',
          }}
        >
          {LOGIN_LABEL.title_01}
        </Heading>

        <Input
          name="email"
          label="Email"
          aria-label="email"
          value={email || ''}
          onChange={({ target }) => setEmail(target.value)}
          rules={validations.login.email}
          className="mb-5"
        />

        <Input
          name="password"
          label="Password"
          aria-label="password"
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          rules={validations.login.password}
          className="mb-5"
        />

        {hasFailedLogin && (
          <Body
            size="large"
            color={theme.color?.CONTENT_NEGATIVE}
            className="mb-4 text-center"
          >
            {LOGIN_LABEL.errorMessage}
          </Body>
        )}

        <div className="flex ai-center mb-4">
          <div className="text-right flex-1">
            <Link
              href={{
                pathname: '/login/forgotpassword',
                query: { ...(email ? { email } : {}) },
              }}
              style={{ color: theme.color?.CONTENT_PRIMARY }}
              className="underline"
            >
              {LOGIN_LABEL.forgotPassword}
            </Link>
          </div>
        </div>

        <Button
          className="mb-4"
          type="submit"
          disabled={shouldDisableButton() || isLoading}
          loading={isLoading}
          fullWidth
          onClick={onClickLogin}
        >
          {LOGIN_LABEL.login}
        </Button>

        <Body
          className="create-account-label"
          size="large"
          color={theme.color?.CONTENT_PRIMARY}
        >
          {LOGIN_LABEL.createAccount_01}
          <a
            href={`${ENV.ONBOARDING_WEB_URL}/signup`}
            style={{ color: theme.color?.CONTENT_PRIMARY }}
            className="underline"
          >
            {LOGIN_LABEL.createAccount_02}
          </a>
        </Body>
      </div>
    </>
  )
}

export { LoginCard }
