import { Button, Input, Body, Heading } from '@moneylion/mlds-web'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { validate, validations } from '@root/utils'
import labels from './labels'

interface ForgotPasswordViewProps {
  email: string
  setEmail: (email: string) => void
  handlePasswordReset: () => Promise<string>
}

const ForgotPasswordView: React.FunctionComponent<ForgotPasswordViewProps> = ({
  email,
  setEmail,
  handlePasswordReset,
}) => {
  const theme = useTheme()
  const router = useRouter()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onPasswordReset = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    try {
      setIsLoading(true)
      await handlePasswordReset()
    } catch {
      setIsLoading(false)
    }
  }

  const shouldDisableButton = (): boolean => {
    return isLoading || !validate({ email }, validations.login)
  }

  return (
    <div className="forgot-password-card">
      <div className="heading">
        <Heading style={{ marginBottom: '16px' }} size="medium">
          {labels.forgotPassword.forgotYourPassword}
        </Heading>
        <Body
          className="mb-4"
          size="medium"
          color={theme.color?.CONTENT_SECONDARY}
        >
          {labels.forgotPassword.resetYourPasswordInfo}
        </Body>
      </div>
      <form onSubmit={onPasswordReset}>
        <Input
          name="email"
          label={labels.forgotPassword.email}
          aria-label="email"
          className="mb-7"
          onChange={({ target }) => setEmail(target.value)}
          rules={validations.login.email}
          value={email}
        />
        <Button
          className="mb-3"
          fullWidth
          disabled={shouldDisableButton()}
          type="submit"
          color="secondary"
          loading={isLoading}
        >
          {labels.forgotPassword.resetYourPassword}
        </Button>
        <div className="flex login-label">
          <Body className="mr-1" color={theme.color?.CONTENT_SECONDARY}>
            {labels.forgotPassword.backTo}
          </Body>
          <Body
            color={theme.color?.CONTENT_PRIMARY}
            className="underline cursor"
            onClick={() => router.push('/login')}
          >
            {labels.forgotPassword.logIn}
          </Body>
        </div>
      </form>
    </div>
  )
}

export { ForgotPasswordView }
